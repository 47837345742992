// Footer (c) 2022. Ayuntamiento de Valladolid

import {useTranslation} from "react-i18next";
// @ts-ignore
import eu from '../assets/finaciado_eu.png';
// @ts-ignore
import ministerio from '../assets/MPT.Gob.png';
// @ts-ignore
import prtr from '../assets/logo_prtr.png';

/**
 * Función que nos imprime la zona de copyright de la página...
 *
 * @author David Rodríguez Alfayate (drodriguez@divisait.com)
 */
export function Footer() {
    const {t} = useTranslation();
    return <footer className="copyright">
                <div className="financed">
                    <div className="eu-logo">
                        <img className="eu" src={eu} alt={t('eu-financed')}/>
                    </div>
                    <div className="other-logo">
                        <img className="ministerio" src={ministerio} alt={t('ministerio')}/>
                        <img className="prtr" src={prtr} alt={t('prtr')}/>
                    </div>
                </div>
                <div className="copyright-txt">{t('copyright')}</div>
           </footer>

}
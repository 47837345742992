import * as i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from "i18next-resources-to-backend";

const i18nLocalInstance = i18n.createInstance();
i18nLocalInstance.use(ChainedBackend).init({
    debug: false,
    defaultNS: ['sqi'],
    supportedLngs: ['es'],
    fallbackLng: 'es',
    saveMissing: false,
    initImmediate: false,
    lng: window.navigator.language,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
        backends: [
            HttpApi,
            resourcesToBackend((lng, ns, clb) => {
                import(`./locales/${lng}/${ns}.json`)
                    .then((resources) => clb(null, resources))
                    .catch(clb)
            })
        ],
        backendOptions: [{
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        }]
    }
}, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
});


export default i18nLocalInstance;
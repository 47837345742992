import {UISelect} from "proxia-react";
import {IPreferencesPrototype} from "./IPreferencesPrototype";
import {PreferencesTitle} from "./PreferencesTitle";
import {useTranslation} from "react-i18next";
import {MailPeriodicity} from "../model/MailPeriodicity";

/**
 * Función encargada de pintar la información de cambio de datos de notificaciones del usuario
 *
 * @author David Rodríguez Alfayate
 */
export function UserNotificationData({visible,activate}:IPreferencesPrototype) {
    const {t} = useTranslation();
    return <section className="preferences notificationData">
                <PreferencesTitle title="notificationData" visible={visible} activate={activate}/>
                {visible?<div className="form-group">
                                <UISelect name="periodicity"
                                          options={()=>Object.keys(MailPeriodicity).filter(n=>isNaN(Number(n))).reduce((previous,next)=>{
                                    previous.push({value:next,label:t(next)});
                                    return previous;
                                },[] as {label:string,value:string}[])}/>
                            </div>:<></>}
    </section>
}
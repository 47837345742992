import {UIInput, UIInputTypeEnum} from "proxia-react";
import {useTranslation} from "react-i18next";
import {IPreferencesPrototype} from "./IPreferencesPrototype";
import {PreferencesTitle} from "./PreferencesTitle";

/**
 * Función encargada de pintar la información de cambio de contraseña del usuario conectado
 *
 * @author David Rodríguez Alfayate
 */
export function UserPasswdData({visible,activate}:IPreferencesPrototype) {
    const {t} = useTranslation();
    return <section className="preferences passwdData">
                <PreferencesTitle title="passwdData" visible={visible} activate={activate}/>
                {visible?<>
                    <p className="passwd-disclaimer">{t('passwdDisclaimer')}</p>
                    <div className="form-group">
                        <UIInput name="oldPasswd" type={UIInputTypeEnum.password}/>
                    </div>
                    <div className="form-group">
                        <UIInput name="newPasswd" depends={"oldPasswd"}
                                 type={UIInputTypeEnum.password} required={({oldPasswd})=> {
                            return !!oldPasswd;
                        }}/>
                    </div>
                    <div className="form-group">
                        <UIInput name="newPasswd2" depends={["oldPasswd","newPasswd"]}
                                 validate={(value,{newPasswd})=>{
                                     return value === newPasswd;
                                 }}
                                 type={UIInputTypeEnum.password}
                                 validationErrorMessage={t('passwdMismatch')}
                                 required={({oldPasswd,newPasswd})=>!!oldPasswd || !!newPasswd}/>
                    </div>
                </>:<></>}
            </section>

}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18nLocalInstance from "./i18n";
import { I18nContext } from 'react-i18next';

// Importamos todo esto para poder utiilzarlo desde fuera...
import * as reactLibrary from 'react';
import * as reactDom from 'react-dom';
import * as proxiaReact from 'proxia-react';
import * as i18n from "i18next";
import * as reactI18next from 'react-i18next';
import * as datePicker from 'react-date-picker';
import * as dropZone from 'react-dropzone';
import * as i18nFetch from 'i18next-http-backend';
import * as proxiaProjectReact from 'proxia-project-react';
import {RestfulServiceElement} from "./service/RestfulServiceElement";
import {ErrorBoundary} from "react-error-boundary";
import {ErrorHandler} from "./ErrorHandler";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Creamos el custom-element que nos define los servicios de Proxia
window.customElements.define('proxia-service',RestfulServiceElement);

root.render(
  <React.StrictMode>
      <I18nContext.Provider value={{i18n:i18nLocalInstance}}>
          <React.Suspense>
              <ErrorBoundary FallbackComponent={ErrorHandler}>
                <App />
              </ErrorBoundary>
          </React.Suspense>
      </I18nContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export {reactLibrary};
export {reactDom};
export {proxiaReact};
export {i18n};
export {reactI18next};
export {datePicker};
export {dropZone};
export {i18nFetch};
export {proxiaProjectReact};



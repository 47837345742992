// Footer (c) Ayuntamiento de Valladolid 2022

import {LogonView} from "./LogonView";
import {User} from "../user/model/User";
import {EndPoint, LoadingManager, useApi, useCallProvider, Utils} from "proxia-react";
import {useEffect, useState} from "react";

/**
 * Función orientada a realizar la autenticación del usuario ante el servidor, delega
 * la visualización de datos en la información de la vista.
 *
 * @param afterLogon    Función que utilizaríamos para indicar al sistema que el usuario
 *                      está autenticado.
 *
 * @author David Rodríguez Alfayate (drodriguez@divisait.com)
 */
export function LogonPresenter({afterLogon}:{afterLogon:(user:User)=>void}) {
    const api = useApi();
    const call = useCallProvider();
    const [loading,setLoading] = useState(true);

    // Intentamos ver si estamos preautenticados...
    useEffect(()=>{
        api.json(call(new EndPoint('/sqi-api/me',true)))
           .then((u)=>afterLogon(new User(u)))
           .catch(()=>setLoading(false));
    },[]);


    // Función que realiza el logon ante el sistema remoto.
    const logon = async ({login,passwd}:{login:string,passwd:string})=>{
                        // Tendríamos que indicar que estamos ya autenticados y podemos
                        // proceder al proceso de autenticación
                        try {
                            const u = await api.json(call(new EndPoint('/sqi-api/logon',true)), api.queryStringBody({login, passwd}));
                            if (!u || Utils.isEmpty(u)) {
                                return false;
                            }
                            afterLogon(new User(u));
                            return "NOOP" as "NOOP";
                        } catch(e) {
                            return false;
                        }
                   }

    return <LoadingManager loading={loading}>
                <LogonView doLogon={logon}/>
           </LoadingManager>

}
// Footer (c) 2022. Ayuntamiento de Valladolid

import React from "react";
import {User} from "./model/User";

/**
 * Costante global del sistema que mantiene la información del usuario conectado, en función
 * del estado de la misma (si hay usuario o no), lo que hará será mostrar la ventana
 * de logon o la ventana en la que se encuentre el usuario en ese momento determinado
 *
 * @author David Rodríguez Alfayate
 */
export const UserContext = React.createContext<User|undefined>(undefined);
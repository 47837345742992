// Footer (c) Ayuntamiento de Valladolid 2022

import {UIForm, UIInput, UIInputTypeEnum} from "proxia-react";
import {useTranslation} from "react-i18next";
import {Header} from "../shared/Header";
import {Footer} from "../shared/Footer";

/**
 * Vista de logon, imprime el formulario y llama a la función de autenticación una vez se han introducido
 * los datos correctos de conexión
 *
 * @param doLogon   Callback para hacer el proceso de logon en el sistema, recibe como parámetros el login
 *                  y contraseña de conexión al sistema, va a devolver el resultado de la operación
 *                  que puede ser "no hacer nada" o false en caso de error.
 *
 * @author David Rodríguez Alfayate (drodriguez@divisait.com)
 */
export function LogonView({doLogon}:{doLogon:({login,passwd}:{login:string,passwd:string})=>Promise<"NOOP"|boolean>}) {
    const {t} = useTranslation();

    return <div className="login-view">

            <div className="form-container">
               <Header/>
               <div className="form">
                   <UIForm form={{login:'',passwd:''}}
                           onSubmit={doLogon}
                           toastr={false}
                           error={()=><>{t('loginError')}</>}>
                        <h2>{t('welcome')}</h2>
                        <p>{t('logonIntro')}</p>
                        <div className="form-group">
                            <UIInput data-testid="login" name="login" type={UIInputTypeEnum.text} required/>
                        </div>
                        <div className="form-group">
                            <UIInput data-testid="passwd" name="passwd" type={UIInputTypeEnum.password} required/>
                        </div>
                        <div className="actions">
                            <button data-testid="logon-button" className="btn btn-primary" type="submit">{t('signIn')}</button>
                        </div>
                   </UIForm>
               </div>
               <Footer/>
            </div>
           <div className="detail"></div>
        </div>

}
//  Footer (c) 2022. Ayuntamiento de Valladolid

import React, {useEffect, useState} from 'react';
import './App.css';
import {LogonPresenter} from "./logon/LogonPresenter";
import {User} from "./user/model/User";
import {UserContext} from "./user/UserContext";
import {LayoutPresenter} from "./layout/LayoutPresenter";
import {NetworkStateRouting, useAbsoluteCall,network} from "proxia-react";
import {LogonView} from "./logon/LogonView";

/**
 * Ventana principal de la aplicación, simplemente se encarga de actuar como un router de entrdada para
 * detectar si el usuario se encuentra o no conectado y en función de ello dirigirle a la página
 * correspondiente.
 *
 * @author David Rodríguez Alfayate (drodriguez@divisait.com)
 */
function App() {
    // Estado de conexión del usuario activo, lo mantenemos en un estado
    const [user,setUser] = useState<User|undefined>();//()=>new User({login:'p',email:'pepe@pepe.es',fullname:'Pepe perez',organizationUserMode:OrganizationUserMode.EXTERNAL}));
    const [localApi,setLocalApi] = useState<network|null>(null);

    const logoff = useAbsoluteCall('/sqi-api/logoff');

    // Reemplazamos el sistema de fetch global del sistema, simplemente con el objetivo de implementar un mecnismo de volver a la página
    // de login en el caso de que el servidor de un error 401 o 403.
    useEffect(() => {
        const originalFetch = window.fetch;

        window.fetch = async(input: RequestInfo | URL, init?: RequestInit) => {
            const response = await originalFetch(input,init);
            if(response.status === 401 || response.status === 403) {
                setUser(undefined);
            }
            return response;
        }
        // Indicmos que tenemos el API local para hacer peticiones utilizando este sistema.
        setLocalApi(new network());

        // Si desmontamos la aplicación dejamos el fetch como se encontraba.
        return ()=>{
            window.fetch = originalFetch;
        }

    },[]);

    // Efecto cuando se produce el proceso de autenticación, tenemos que registar
    // los métodos que permiten hacer un logoff y un update de los datos del
    // usuario conectado
    useEffect(()=>{
        if(user) {
            user.logoff = () => {
                // Queremos que haga un logoff pero nos da igual... nosotros independientemente de ello
                // vamos a hacer un setUser a undefined, que es nuestro logoff interno.
                localApi?.json(logoff).then(()=>setUser(undefined));
            }
            user.update = (u:User) => {
                setUser(u);
            }
        }
    },[user]);

    // La función de logon, simplemente se encarga de controlar la recepción
    // del usuario para activar el contexto y permitir que a partir de
    // ese momento el sistema sea capaz de operar con la nueva función
    const doLogon = (authUser:User)=>{
        setUser(authUser);
    }
    // Nos aseguramos que realmente no cargue nada, hasta que inicie el sistema de fetch local, evitando así
    // que este en una situación "rara", siempre, lo mejor, es que este bien controlado.
    if(!user) return <NetworkStateRouting loading={!localApi} data={{isFetch:true}}
                                          firstLoadSnapshot={()=><LogonView doLogon={()=>Promise.resolve(false)}/>}
                                          render={()=><LogonPresenter afterLogon={doLogon}/>}/>
    return <UserContext.Provider value={user}>
                <LayoutPresenter/>
           </UserContext.Provider>
}

export default App;

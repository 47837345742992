// Footer (c) 2022. Ayuntamiento de Valladolid

// @ts-ignore
import logo from '../assets/logo_ava_top.png';
// @ts-ignore
import txt from "../assets/text_ava_top.png"
import {useTranslation} from "react-i18next";
/**
 * Simplemente genera una capa con la información del logotipo del ayuntamiento de tal
 * forma que se cumplan criterios de accesibilidad
 *
 * @author David Rodríguez Alfayate (drodriguez@divisait.com)
 */
export function Header() {
    const {t} = useTranslation();
    return <header className="mainHeader">
                <div className="logos">
                    <div className="img">
                        <img src={logo} alt="Ayuntamiento de Valladolid"/>
                    </div>
                    <div className="txt">
                        <img src={txt} alt=""/>
                    </div>
                </div>
                <h1 className="title">{t('projectTitle')}</h1>
           </header>

}